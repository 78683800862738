import React, {useEffect, useRef, useState} from "react";
// @ts-ignore
import {Entity} from 'aframe-react';
import {Minimap} from "./ui/VR/Minimap";
import {launchPanoramaChangeAnimations} from "./common";
import {ControllerInstructions} from "./ui/VR/ControllerInstructions";
import glow from "../images/ui/glow.png";
import {PanoramaTour} from "../api/apiClient";
import {RoomImageMenu} from "./ui/VR/RoomImageMenu";
import {CameraRig} from "./camera/CameraRig";

export interface ViewControlProps {
    tour: PanoramaTour;
    currentPanorama: string;
    changePanorama: (panorama: string) => void;
    isVrMode: boolean;
}

export function ViewControl(props: ViewControlProps) {
    const { tour, currentPanorama, changePanorama,
        isVrMode } = props;

    const { rooms, panoramas, floorPlans } = tour;

    const [vrMinimapVisible, setVrMinimapVisible] = useState(false);
    const [vrRoomMenuVisible, setVrRoomMenuVisible] = useState(false);
    const [vrSelectedFloorIndex, setVrSelectedFloorIndex] = useState<number>(findCurrentFloorIndex());

    // Ugly, but the rotationReader needs to update once the VR minimap component has mounted.
    const [vrMinimapReady, setVrMinimapReady] = useState(false);

    useEffect(() => {
        const currentPanoramaFloorIndex = findCurrentFloorIndex();
        if (currentPanoramaFloorIndex > 0 && vrSelectedFloorIndex !== currentPanoramaFloorIndex) {
            setVrSelectedFloorIndex(currentPanoramaFloorIndex);
        }
    }, [currentPanorama]);

    const panorama = panoramas.find((panorama) => panorama.name === currentPanorama);

    const vrRoomMenuRef = useRef<Entity>(null);

    if (!panorama) {
        return <></>;
    }

    const openMenuAnimation = {property: "scale", easing: "easeOutQuad", from: "0 0 0", to: "1 1 1", dur: 700, startEvents: "openmenu"}
    const closeMenuAnimation = {property: "scale", easing: "easeOutQuad", from: "1 1 1", to: "0 0 0", dur: 700, startEvents: "closemenu"}

    function closeMenu(menu: React.MutableRefObject<Entity>, onCloseAnimationFinished: () => void) {
        if (menu.current) {
            menu.current.el.emit("closemenu", null, false);
        }
        setTimeout(() => onCloseAnimationFinished(), 700);
    }

    function findCurrentFloorIndex() {
        return floorPlans.findIndex((fp) =>
            fp.hotspots.some((hp) => hp.targetPanorama === currentPanorama))
    }

    return <>

        <Entity cursor="rayOrigin: mouse" raycaster="objects: .raycastable" id="mouse-cursor"
                events={{
                    // End the house tour when user takes control.
                    mousedown: (event: any) => {
                         const camera = document.querySelector('#cameraRig');
                         camera.emit("pauseTour", null, false);
                    }
                }}
        />

        <CameraRig panoramas={panoramas} changePanorama={changePanorama} currentPanorama={currentPanorama}
                   isVrMode={isVrMode} vrMinimapVisible={vrMinimapReady}
                   // Support for oculus controller when in VR mode
                   vrController={<>
                       <Entity oculus-touch-controls="hand: left" />
                       <Entity oculus-touch-controls="hand: right" thumb-controls="hand: right"
                             events={{
                                 abuttondown: () => {
                                     setVrMinimapVisible(curr => !curr);
                                 },
                                 bbuttondown: () => {
                                     if (vrRoomMenuVisible) {
                                         closeMenu(vrRoomMenuRef, () => setVrRoomMenuVisible(false));
                                     }
                                     else {
                                         setVrRoomMenuVisible(true);
                                     }
                                 },
                                 thumbleftend: (evt: any) => {
                                     if (vrSelectedFloorIndex > 0) {
                                         setVrSelectedFloorIndex((curr) => (curr ?? 0) - 1);
                                     }
                                 },
                                 thumbrightend: (evt: any) => {
                                     if (vrSelectedFloorIndex < floorPlans.length -1) {
                                         setVrSelectedFloorIndex((curr) => (curr ?? 0) + 1);
                                     }
                                 }
                             }}
                        >
                           {isVrMode && <ControllerInstructions hideRoomButton={rooms.length === 0} hideFloorPlanButton={floorPlans.length === 0} />}

                           {isVrMode && vrMinimapVisible && floorPlans.length > 0 && <Entity position="0 0 -0.2" scale="0.05 0.05 0.05" rotation="-110 0 0">
                                <Minimap floorPlans={floorPlans} currentPanorama={currentPanorama} selectedFloorIndex={vrSelectedFloorIndex}
                                         onHotSpotClick={changePanorama} onClose={() => setVrMinimapVisible(false)} onSelectedFloorChanged={setVrSelectedFloorIndex}
                                mounted={() => setVrMinimapReady(true) }/>
                           </Entity>}
                        </Entity>
                   </>}
        />

        {/* The panorama image */}
        <Entity id="sky" key="sky" primitive="a-sky" src={"#" + currentPanorama} radius="100" transparent="false" rotation={panorama.cameraRotation}
                light="type:ambient; castShadow:false;"
                animation__fade="property: material.opacity; type: number; easing: linear; from: 1; to: 0.5; dur: 700; startEvents: fade"
                animation__fadeback="property: material.opacity; type: number; easing: linear; from: 0.5; to: 1; dur: 400; startEvents: animationcomplete__fade" />

        { // Create hotspots. This creates both the spots and arrows.
            panorama.hotspots.map((spot) => {

                const isSpot = spot.type === "spot";

                // Spots appear to be floating in VR mode if they are not moved farther.
                const vrMultiplier = 4;
                const vrSpotPosition = {x: spot.position.x * vrMultiplier, y:spot.position.y * vrMultiplier, z: spot.position.z * vrMultiplier};

                const spotMaterial = {flatShading: true, color: "white", side: "double", opacity: isVrMode ? 1 : 0.5}; // opaque objects that are far from camera look bad for some reason in vr mode.
                const arrowMaterial = {opacity: 0.7, height: 0.2, width: 0.4, side: "double", src: `#arrow-${spot.arrowDirection}`};

                const id = panorama.name + "-" + spot.targetPanorama;
                return <Entity id={id} key={id}
                               position={isVrMode && isSpot ? vrSpotPosition : spot.position}
                               primitive={!isSpot ? "a-image" : ""}
                               proxy-event__showglow={`event: mouseenter; to: #glow-${id}; as: show`}
                               proxy-event__hideglow={`event: mouseleave; to: #glow-${id}; as: hide`}
                               proxy-event__fade={`event: click; to: #sky; as: fade`}
                               events={{click: () => {
                                       launchPanoramaChangeAnimations(spot.position);
                                       setTimeout(() => {
                                           changePanorama(spot.targetPanorama);
                                       }, 700);
                                   } }}
                               class="raycastable panoramaHotspot"
                               geometry={isSpot ? {primitive: "cylinder", radius: isVrMode ? 1.2 : 0.3, height: 0.01} : {}}
                               material={isSpot ? spotMaterial : arrowMaterial}
                               look-at={isSpot ? undefined : "#camera"}
                >
                    {/*The glow is a transparent image which is shown when mouse is hovering over the hotspot.*/}
                    <Entity primitive="a-image" key={"glow-" + id} id={"glow-" + id} class="hotspotglow" position="0 -0.01 -0.01"
                            material={{src: glow, side: "double", opacity: 0}}
                            geometry={{primitive: "plane", height: isVrMode ? 6 : 1.5, width: isVrMode ? 6 : 1.5 }}
                            rotation={isSpot ? "90 0 0" : "0 0 0"}
                            animation__show="property: material.opacity; type: number; easing: linear; from: 0; to: 0.5; dur: 300; startEvents: show"
                            animation__hide="property: material.opacity; type: number; easing: linear; from: 0.5; to: 0; dur: 300; startEvents: hide"
                    />
                </Entity>;
            })
        }

        {vrRoomMenuVisible && isVrMode && rooms.length > 0 && <Entity id={"vrRoomImageUI"} position="0 0.2 -1"
                                     look-at={"#camera"}
                                     menucontainer
                                     animation__openmenu={openMenuAnimation}
                                     animation__hideui={closeMenuAnimation}
                                     ref={vrRoomMenuRef}
        >
            <RoomImageMenu itemsPerRow={6} roomImages={rooms}
               onItemClick={(newPanorama) => {
                   changePanorama(newPanorama);
                   setVrRoomMenuVisible(false);
            }} />
        </Entity>}
    </>
}